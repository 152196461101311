
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'ClearButton',
    props: {
        translation: {
            type: String as PropType<string>,
            required: true
        },
    }
});
