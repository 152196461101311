export const t = (translation: string, wordsToReplace: Record<string, string | number>): string => {
    if (!translation) {
        return '';
    }
    if (!wordsToReplace) {
        return translation;
    }
    
    let parsedString = translation;

    Object.entries(wordsToReplace).forEach(([key, wordToReplace]) => {
        const pattern = new RegExp(`%${key}%`, "g");
        parsedString = parsedString.replace(pattern, String(wordToReplace));
    })

    return parsedString;
}
