/* eslint-disable no-new */

import { createApp } from 'vue'
import Search from './Search.vue'

const mountPoint = document.currentScript.previousElementSibling;
createApp({
    components: {
        Search,
    },
}).mount(mountPoint)
