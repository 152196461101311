import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "transition-opacity cursor-pointer hover:opacity-80 text-bold rounded-input flex justify-center items-center p-4 text-sm",
  "clear-button": ""
}
const _hoisted_2 = {
  key: 0,
  class: "mr-1 search-hidden sm:block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.translation)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.translation), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "bg-search-icon-clear w-4 h-4 bg-no-repeat bg-left" }, null, -1 /* HOISTED */))
  ]))
}