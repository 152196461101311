
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'GameGrid',
    inject: ['webfrontParams'],
    data() {
        return {
            injectedParams: this.webfrontParams as unknown as Record<string, string>,
        };
    },
});
