
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'SearchPill',
    props: {
        name: {
            type: String,
            require: false,
            default: 'temp',
        },
    },
});
