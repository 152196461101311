import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["justify-center", {
             'grid auto-rows-[1fr] grid-cols-search-results sm:grid-cols-sm-search-results md:grid-cols-md-search-results lg:grid-cols-lg-search-results gap-[16px]': _ctx.injectedParams.theme !== 'JACKPOTJOY_SE',
             'flex flex-row flex-wrap': _ctx.injectedParams.theme === 'JACKPOTJOY_SE',
         }])
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2 /* CLASS */))
}