
import { defineComponent, PropType } from 'vue';
import { Game } from '../interfaces/interfaces';

export default defineComponent({
    name: 'SearchResult',
    props: {
        searchResult: {
            type: Object as PropType<Game>,
            required: true,
        },
        format: {
            type: String,
            require: false,
            default: 'default',
        },
        position: {
            type: Number,
            require: true
        },
        launchOrigin: {
            type: String,
            require: false,
            default: "search"
        }
    },
    inject: ['webfrontParams'],
    data() {
        return {
            injectedParams: this.webfrontParams as unknown as Record<string, string>,
        };
    },
    computed: {
        isLoggedIn() {
            return !!this.injectedParams?.playertoken;
        },
        gameUrl() {
            const urlSearchParams = new URLSearchParams({
                launchOrigin: this.launchOrigin,
                launchOriginUrl: window.location.pathname,
                position: this.position?.toString() ?? "undefined"
            });
            return new URL(`/play/${this.searchResult.url_alias}?${urlSearchParams.toString()}`, window.location.origin).toString();
        },
        badges() {
            return JSON.stringify(this.searchResult.badges);
        },
        liveData() {
            return (this.searchResult.live_casino_game_info)
                ? JSON.stringify(this.searchResult.live_casino_game_info)
                : null;
        }
    }
});
