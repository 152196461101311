import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "search-result-skeleton": "",
  class: "flex flex-col animate-pulse opacity-80"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("div", { class: "w-[150px] h-[150px] bg-bg-placeholder rounded-checkbox mb-3" }, null, -1 /* HOISTED */),
    _createElementVNode("div", { class: "grow flex flex-row justify-between pr-2" }, [
      _createElementVNode("div", { class: "h-4 w-[110px] bg-bg-placeholder rounded-checkbox" }),
      _createElementVNode("div", { class: "h-4 w-[20px] bg-bg-placeholder rounded-checkbox" })
    ], -1 /* HOISTED */)
  ])))
}