import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["is-logged-in", "is-favourited", "jackpot-amount", "url-alias", "display-name", "icon-url", "foreground-icon-url", "background-icon-url", "game-uuid", "badges", "live-data", "launch-origin", "position", "site-id", "player-flag", "country", "language", "currency"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.format === 'image')
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        "search-result": "",
        class: "w-1/2 md:w-1/3 lg:w-1/4 flex p-2 space-x-2 flex rounded-checkbox h-24 sm:h-32 lg:h-48",
        rel: "nofollow",
        href: _ctx.gameUrl
      }, [
        _createElementVNode("img", {
          class: "w-full transition object-cover hover:scale-down",
          src: _ctx.searchResult.icon,
          alt: _ctx.searchResult.display_name
        }, null, 8 /* PROPS */, _hoisted_2)
      ], 8 /* PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("game-tile", {
        key: 1,
        "search-result": "",
        class: "min-h-search-game-tile-height",
        "is-logged-in": _ctx.isLoggedIn ? 'true' : null,
        "is-favourited": _ctx.searchResult.is_favourite ? 'true' : null,
        "jackpot-amount": _ctx.searchResult.formatted_jp_amount,
        "url-alias": _ctx.searchResult.url_alias,
        "display-name": _ctx.searchResult.display_name,
        "icon-url": _ctx.searchResult.icon,
        "foreground-icon-url": _ctx.searchResult.foreground_icon,
        "background-icon-url": _ctx.searchResult.background_icon,
        "game-uuid": _ctx.searchResult.game_id,
        badges: _ctx.badges,
        "live-data": _ctx.liveData,
        "launch-origin": _ctx.launchOrigin,
        position: _ctx.position,
        "site-id": _ctx.injectedParams.siteid,
        "player-flag": _ctx.injectedParams.player_flag ?? '1',
        country: _ctx.injectedParams.country,
        language: _ctx.injectedParams.language,
        currency: _ctx.injectedParams.currency
      }, null, 8 /* PROPS */, _hoisted_3))
}